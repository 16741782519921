import React, { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../../utils/hooks/useAuth';
import { cn } from '../../../../utils/cn';
import CrmParametresEquipeView from './views/equipe/page.jsx';
import CrmParametresServiceView from './views/service/page.jsx';
import { useNavigate, useParams } from 'react-router-dom';
import CrmParametresEntrepriseView from './views/entreprise/page.jsx';

const CrmParametresView = () => {

    const {user} = useAuth()
    const [view, setView] = useState("equipe")
    const navigate = useNavigate()
    const { pageView } = useParams()


    const tabsSettings = useMemo(() => {
        const tabs = []

        if(user.estAdminOuManager) {
            tabs.push({
                label: "Equipe",
                id: null
            })
        }

        if(user.niveau >= 10) {
            tabs.push({
                label: "Service",
                id: "service"
            })
        }

        if(user.estAdmin) {
            tabs.push({
                label: "Entreprise",
                id: "entreprise"
            })
        }
        return tabs;
    }, [user])

    return (
        <div className='flex flex-1 w-full flex-col'>


         <div className="flex shrink-0 items-center atna-invisible-scrollbar gap-x-[0px] border-b-[1px] border-b-black/5 mt-[30px]  overflow-x-auto">
            {tabsSettings.map(tp => {
                                        return (
                                            <div
                                                key={tp.id}
                                                onClick={() => {
                                                    navigate(`/crm/parametres${tp.id ? `/${tp.id}` : ""}`)
                                                }}
                                                className={cn("text-[12px] w-max font-medium px-[20px] h-[30px]  flex items-center justify-center cursor-pointer text-nowrap ",
                                                    ((pageView||null) === tp.id || !tp.id && pageView === "equipe") ? "bg-black/5" : " hover:bg-black/[0.02]"
                                                )}>
                                                {tp.label}
                                            </div>
                                        )
                                    })
                }
        </div>

        {
            pageView === "entreprise" ? (
                <CrmParametresEntrepriseView currentView={pageView} key={pageView}/>
            )
            : 
            pageView === "service" ? (
                <CrmParametresServiceView currentView={pageView} key={pageView}/>
            )
            :
            (
            <CrmParametresEquipeView currentView={pageView} key={pageView}/>
            ) 
            
        }

        </div>
    );
}

export default CrmParametresView;
