import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Notes d'opportunités -> fiche tiers
  // récuperer histoire des etats de lopportnite
  export const fetchTiersOpportuniteNotes = async ({ id, idOpportunite }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/opportunites/${id}/${idOpportunite}/notes`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching tiers ${id} opportunitie notes ${idOpportunite} ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération des notes de l'opportunité.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };



// Créer une note d'opportunité
  export const createTiersOpportuniteNote = async ({ id, idOpportunite, contenu }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/opportunites/${id}/${idOpportunite}/notes/create`,
        {
          method: "POST",
          withCredentials: true,
          data: {
            contenu
        }
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Creating tiers ${id} opportunitie notes ${idOpportunite} ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la création de la note.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };


// Supprimer une note d'opportunité
  export const deleteTiersOpportuniteNote = async ({ id, idOpportunite, idNote}) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/opportunites/${id}/${idOpportunite}/notes/${idNote}/delete`,
        {
          method: "DELETE",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Creating tiers ${id} opportunitie notes ${idOpportunite} ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la création de la note.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };