import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalState } from '../utils/hooks/useGlobalState';
import { upperFirst } from 'lodash';
import { fetchUserPhoto } from '../utils/atna';

const Personnel = ({ id, center = false, upper = false, crop = false, hidden = false }) => {
  const { listPersonnel } = useGlobalState();
  const [isHovered, setIsHovered] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [userPhoto, setUserPhoto] = useState(null);

  const personnel = listPersonnel?.find((e) => e.id === id);
  const nomComplet = personnel?.nomComplet || id;

  // Charger la photo une fois
  useEffect(() => {
    const fetchPhoto = async () => {
      if (personnel?.email) {
        const response = await fetchUserPhoto({ email: personnel.email, size: "48x48" });
        if (response.status === 200 && response.data) {
          setUserPhoto(response.data);
        }
      }
    };
    fetchPhoto();
  }, [personnel?.email]);

  // Calculer la position du popup
  const calculatePopupPosition = useCallback((rect) => {
    const popupWidth = 220;
    const margin = 10;

    let top = rect.bottom + margin;
    let left;

    if (center) {
      // Centrer le popup horizontalement par rapport à l'élément
      left = rect.left + (rect.width / 2) - (popupWidth / 2);
      if (left < margin) {
        // Si déborde à gauche
        left = margin;
      } else if (left + popupWidth > window.innerWidth) {
        // Si déborde à droite
        left = window.innerWidth - popupWidth - margin;
      }
    } else {
      // Aligner à gauche ou à droite selon la position de l'élément
      const align = rect.left < window.innerWidth / 2 ? 'left' : 'right';
      left = align === 'left' ? rect.left : rect.right - popupWidth;

      // Ajuster si débordement
      if (align === 'left' && left + popupWidth > window.innerWidth) {
        left = window.innerWidth - popupWidth - margin;
      } else if (align === 'right' && left < 0) {
        left = margin;
      }
    }

    return { top, left };
  }, [center]);

  const handleMouseEnter = (e) => {
    const rect = e.target.getBoundingClientRect();
    setPopupPosition(calculatePopupPosition(rect));
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div
        className="inline-block w-max"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span>
        {
        crop ?
        `${upperFirst(nomComplet.split(" ")[0]?.toLowerCase())} ${nomComplet.split(" ")[1]?.charAt(0).toUpperCase()}.`
        :
        upper ? 
        nomComplet
        .split(" ")
        .map((part) => upperFirst(part.toLowerCase()))
        .join(" ")
        :
        nomComplet}</span>
      </div>

      {isHovered && !hidden && (
        <div
          className={`fixed atna-drop-menu text-black text-sm rounded-md px-[15px] py-[10px] shadow-lg transition-opacity duration-300 ${
            isHovered ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
          style={{
            top: popupPosition.top,
            left: popupPosition.left,
            width: "100%",
            maxWidth: 220,
            zIndex: 9999,
          }}
        >
          <div className="flex items-center gap-x-[15px]">
            <div className="size-[35px] rounded-full bg-black/[0.04] overflow-hidden">
              {userPhoto && (
                <img src={userPhoto} alt="User" className="w-full h-full" />
              )}
            </div>
            <h1 className="text-[12px] leading-[14px] opacity-90 font-semibold">
              {nomComplet
                .split(" ")
                .map((part) => upperFirst(part.toLowerCase()))
                .join(" ")}
            </h1>
          </div>
        </div>
      )}
    </>
  );
};

export default Personnel;
