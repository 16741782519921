import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { editTeam, fetchUserService, fetchUserTeams } from '../../../../../../utils/atna';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts } from '../../../../../../utils/hooks/useAlert';
import { useAuth } from '../../../../../../utils/hooks/useAuth';
import { cn } from '../../../../../../utils/cn';
import { fetchEquipeSecteurs, fetchEquipeUsers } from '../../../../../../utils/atna/equipes/equipe';
import { BiLoaderAlt } from 'react-icons/bi';
import Personnel from '../../../../../../components/personnel';
import Secteurs from '../equipe/components/secteurs';
import ListeServices from './subViews/listeServices';
import ListeAllEquipes from './subViews/listeAllEquipes';
import ListeCommunes from './subViews/listeCommunes';
import ListeCellules from './subViews/listeCellules';

const CrmParametresEntrepriseView = ({currentView}) => {

    const [serviceData, setServiceData] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const { addAlert } = useAlerts()
    const [isEdited, setIsEdited] = useState(false);
    const [loading, setLoading] = useState(true)
    const { user } = useAuth()
    const navigate = useNavigate()
    const [subView, setSubView] = useState("equipes")
    const { pageState } = useParams()


    useEffect(() => {
        if (user.permLoaded && user.niveau < 10) return navigate('/crm')
    }, [user])







    async function fetchData() {
        const resApi = await fetchUserService()
        setLoading(() => false)
        if(resApi.status === 200) {
        setServiceData(() => {
            return { ...resApi.data }
        })
        }
    }
    useEffect(() => {
        fetchData()
    }, [])



    //     // Team members
    //     const [users, setUsers] = useState([])

    //     const fetchTeamUsers = useCallback(async () => {
    //         if(!serviceData) return;
    //         const resApi = await fetchEquipeUsers({id: serviceData.id})
    //         if (resApi.status === 200) {
    //             setUsers(resApi.data)
    //         }
    //     }, [serviceData]
    // )
    //     useEffect(() => {
    //         fetchTeamUsers()
    //     }, [fetchTeamUsers])

  

        const allSubTabs = useMemo(() => {
            let allTabs = [
                { label: "Services", id: null},
                { label: "Equipes", id: 'equipes' },
                { label: "Communes", id: "communes" },
                { label: "Cellules", id: "cellules" },
            ]
            return allTabs
        }, [currentView])




    return (
        <div className='flex-1 flex flex-col w-full'>


            {
                loading ? (
                    <div className="flex-[1_1_auto]">
                        <div className="w-full mt-[80px] flex justify-center items-center h-1/3">

                            <BiLoaderAlt className='animate-spin text-[15px] text-black/50' />

                        </div>
                    </div>
                )
                    :
                        (
                            <div className="flex-1 flex flex-col w-full py-[20px] px-[10px]">
                                <h1 className='text-[14px] font-medium text-black/80'>
                                    IT & You
                                </h1>
                                <h2 className='text-[13px] font-medium text-black/50'>
                                    <Personnel id={user.id} upper/>
                                </h2>


                                <div className="h-[1px] w-full bg-black/[0.04] my-[10px]"></div>


                                <div className="flex shrink-0 items-center atna-invisible-scrollbar gap-x-[0px] border-b-[1px] border-b-black/5 mt-[10px]  overflow-x-auto max-w-[400px]">
                                    {allSubTabs.map(tp => {
                                        return (
                                            <div
                                                key={tp.id}
                                                onClick={() => {
                                                    navigate(`/crm/parametres/entreprise${tp.id ? "/"+tp.id : ""}`)
                                                }}
                                                className={cn("text-[12px] w-max font-medium px-[20px] h-[30px]  flex items-center justify-center cursor-pointer text-nowrap ",
                                                    (pageState||null) === tp.id ? "bg-black/5" : " hover:bg-black/[0.02]"
                                                )}>
                                                {tp.label}
                                            </div>
                                        )
                                    })
                                    }
                                </div>

                                {
                                    !pageState ?
                                    (
                                        <ListeServices/>
                                    )
                                    :
                                    pageState === "equipes" ? (
                                        <ListeAllEquipes/>
                                    )
                                    :
                                    pageState === "communes" ? (
                                        <ListeCommunes/>
                                    )
                                    :
                                    pageState === "cellules" && (
                                        <ListeCellules/>
                                    )
                                }


                                
                            </div>
                        )
            }
        </div>
    );
}

export default CrmParametresEntrepriseView;
