import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { io } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import config from "../config";

// Créez le contexte
const DataContext = createContext();

// Fournisseur de contexte
export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const api = config.dev ? config.devApi : config.api;

    // Initialiser ou récupérer le clientId une seule fois
    const clientId = useMemo(() => {
        let storedClientId = localStorage.getItem('clientId');
        if (!storedClientId) {
            storedClientId = uuidv4(); // Générer un nouvel UUID
            localStorage.setItem('clientId', storedClientId);
        }
        return storedClientId;
    }, []);

    // Mémoriser la configuration du socket pour éviter les re-rendus inutiles
    const initializeSocket = useCallback(() => {
        const newSocket = io(api, {
            withCredentials: true,
            transports: ['websocket'],
            query: {
                clientId: clientId,
            },
            reconnection: true, // Activer la reconnexion automatique
            reconnectionAttempts: Infinity, // Tentatives infinies
            reconnectionDelay: 1000, // Délai initial (1 seconde)
            reconnectionDelayMax: 5000, // Délai maximum entre les tentatives
        });

       // Événements WebSocket
    newSocket.on('connect', () => {
        console.log('Connected to WebSocket server');
    });

    newSocket.on('reconnect', (attemptNumber) => {
        console.log(`Reconnected to WebSocket server after ${attemptNumber} attempts`);
    });

    newSocket.on('disconnect', (reason) => {
        if (reason === 'io server disconnect') {
            console.log('Disconnected by server. Attempting to reconnect...');
        } else {
            console.log('Disconnected from WebSocket server. Reconnecting...');
        }
    });

   

        return newSocket;
    }, [api, clientId]);

    useEffect(() => {
        const newSocket = initializeSocket();
        setSocket(newSocket);

        // Nettoyer lors du démontage
        return () => {
            newSocket.disconnect();
        };
    }, [initializeSocket]);

    // Utiliser `useMemo` pour mémoriser la valeur du contexte
    const value = useMemo(() => ({ socket, clientId }), [socket, clientId]);

    return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

// Custom hook pour utiliser le contexte socket
export const useSocket = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error("useSocket must be used within a SocketProvider");
    }
    return context;
};