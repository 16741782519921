import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Opportunites -> fiche tiers


// Récupérer la liste des oppportunités d'un tiers
export const fetchTiersOpportunites = async ({ id }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/opportunites/${id}`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching tiers ${id} opportunities ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération des opportunités du tiers.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };


  
  // Récupérer la liste des oppportunités d'un user
export const fetchOpportunites = async ({ 
  perso = true,
  details = false,
  teamId = "",
  id_ic = "",
  date = "",
  evaluationCompare,
  evaluation,
  datePrevSignTypeCompare,
  datePrevSignature,
  structure,
  periode,
  prevSignatureYear,
  prevSignatureMonth
} = {}) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/stats/opportunites`,
      {
        method: "GET",
        withCredentials: true,
        params: {
          perso,
          teamId,
          id_ic,
          date,
          details,
          evaluationCompare,
          evaluation,
          datePrevSignTypeCompare,
          datePrevSignature,
          structure,
          periode,
          prevSignatureYear,
          prevSignatureMonth
        }
      },
    ).catch((error) => {
      console.error(
        `[API ERROR] : Fetching opportunities ${error}`
      );
      return {
        status: 400,
        data: "Erreur lors de la récupération de vos opportunités.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error)
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};


  export const fetchTiersOpportuniteDetails = async ({ id, idOpportunite }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/opportunites/${id}/details/${idOpportunite}`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching tiers ${id} opportunitie details ${idOpportunite} ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération de l'opportunité.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };
  




// Modifier une opportunité
export const editTiersOpportunite = async ({ id, idOpportunite, data = {} }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/opportunites/${id}/edit/${idOpportunite}`,
      {
        method: "POST",
        withCredentials: true,
        data,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Editing opportunite for tiers ${id}/${idOpportunite} ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la modification de l'oportunité.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};




// Supprimer une opportunité
export const deleteTiersOpportunite = async ({ id, idOpportunite }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/opportunites/${id}/delete/${idOpportunite}`,
      {
        method: "DELETE",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Deleting opportunities for tiers ${id}/${idOpportunite} ${error}`);
      return { status: 400, data: "Erreur lors de la suppression de l'opportunité." };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};






  /// ---------------------------------------------------------------------------------------------------------------------------------- ///

  // Récupérer la liste des status d'oportuniés ( 1 = en cours, 2 = gagnée , 3 = perdu etc..)
export const fetchTiersOpportunitesStatus = async () => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/opportunites/status`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching tiers opportunities status ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération des status d'opportunités.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };


  // Récupéraiton des etats possible de l'opportunité -> qualif, eval, proposition, nego, cloture etc

  export const fetchTiersOpportunitesEtats = async () => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/opportunites/etats`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching tiers opportunities states ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération des états d'opportunités.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };
  


  // récuperer histoire des etats de lopportnite
  export const fetchTiersOpportuniteEtats = async ({ id, idOpportunite }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/opportunites/etats/${id}/${idOpportunite}`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching tiers ${id} opportunitie states ${idOpportunite} ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération des états",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };


    // Modifier l'étatt - ajouter histoire des etats de lopportnite
    export const editTiersOpportuniteEtat = async ({ id, idOpportunite, etat }) => {
      try {
        const resApi = await axios(
          `${apiBaseUrl}/api/tiers/opportunites/etats/${id}/${idOpportunite}/edit`,
          {
            method: "POST",
            withCredentials: true,
            data: {etat}
          }
        ).catch((error) => {
          console.error(
            `[API ERROR] : Editing tiers ${id} opportunitie states ${idOpportunite} ${error}`
          );
          return {
            status: 400,
            data: "Erreur lors de la modification des états",
          };
        });
        if (!resApi || resApi.status !== 200) {
          return {
            status: 400,
            data: "Erreur lors de la communication vers l'api.",
          };
        }
        const resData = await resApi.data;
        return resData;
      } catch (error) {
        console.log(error)
        return { status: 400, data: "Erreur lors de la communication vers l'api." };
      }
    };


