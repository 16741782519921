import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Notes d'opportunités -> fiche tiers
  // récuperer histoire des etats de lopportnite
  export const fetchEquipeSecteurs = async ({ id }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/teams/${id}/secteurs`,
        {
          method: "GET",
          withCredentials: true,
        }
      )
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };



  export const fetchEquipeUsers = async ({ id }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/teams/${id}/users`,
        {
          method: "GET",
          withCredentials: true,
        }
      )
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };




  export const fetchEquipeSecteurAffectation = async ({ id, idSecteur, id_ic}) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/teams/${id}/secteurs/${idSecteur}/affectation/${id_ic}`,
        {
          method: "GET",
          withCredentials: true,
        }
      )
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };


  export const editEquipeSecteurAffectation = async ({ id, idSecteur, id_ic}) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/teams/${id}/secteurs/${idSecteur}/affectation/${id_ic}/edit`,
        {
          method: "POST",
          withCredentials: true,
        }
      )
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };
  





  // Services
  export const fetchUserService = async ({ fields = [] } = {}) => {
    try {
      const res = await axios(`${apiBaseUrl}/api/teams/service`, {
        method: "GET",
        params: { fields },
        withCredentials: true,
      });
      return res.data;
    } catch (err) {
      return {
        status: 400,
        err: err,
      };
    }
  };
  

  export const fetchUserServiceEquipes = async ({id = null} = {}) => {
    try {
      const res = await axios(`${apiBaseUrl}/api/teams/service/${id}/equipes`, {
        method: "GET",
        withCredentials: true,
      });
      return res.data;
    } catch (err) {
      return {
        status: 400,
        err: err,
      };
    }
  };
  


export const fetchServices = async () => {
  try {
    const res = await axios(`${apiBaseUrl}/api/teams/services/all`, {
      method: "GET",
      withCredentials: true,
    });
    return res.data;
  } catch (err) {
    return {
      status: 400,
      err: err,
    };
  }
};

export const editUserBareme = async ({id = null, bareme = null} = {}) => {
  try {
    const res = await axios(`${apiBaseUrl}/api/teams/users/${id}/bareme`, {
      method: "POST",
      withCredentials: true,
      data: {
        bareme
      }
    });
    return res.data;
  } catch (err) {
    return {
      status: 400,
      err: err,
    };
  }
}