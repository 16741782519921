import React, { act, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { cn } from '../../../../../utils/cn';
import { BiLoaderAlt } from 'react-icons/bi';
import { useAlerts } from '../../../../../utils/hooks/useAlert';
import moment from "moment"
import 'moment/locale/fr'
import InputSelect from '../../../../../components/inputs/inputSelect';
import { useAuth } from '../../../../../utils/hooks/useAuth'
import { useGlobalState } from '../../../../../utils/hooks/useGlobalState'
import { createUserEvent, deleteUserEvent, editUserEvent } from '../../../../../utils/atna';
import TextArea from '../../../../../components/inputs/textArea';
import DateInput from '../../../../../components/inputs/inputDate';
import HourInput from '../../../../../components/inputs/inputHour'
import Input from '../../../../../components/inputs/input'

const AgendaMenu = ({ menuVisible, setMenuVisible, data, baseData, setData, fetchData, isNew, setBaseData }) => {

    const navigate = useNavigate()
    const [isCreating, setIsCreating] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const { addAlert, getAlerts, deleteAlert } = useAlerts()
    const { listPersonnel, listStructure } = useGlobalState();
    const { user } = useAuth()


    useEffect(() => {
    //   console.log(data)
    }, [data])

    const handleUpdateData = async () => {
        setIsCreating(() => true)
        let resApi
        const startDateTime = moment(`${data.startDate} ${data.startHour}`, "YYYY-MM-DD HH:mm").toISOString();
        const endDateTime = moment(`${data.startDate} ${data.endHour}`, "YYYY-MM-DD HH:mm").toISOString();


        console.log(startDateTime, endDateTime)

        if(!data.subject) {
            setIsCreating(() => false)
            return addAlert(`Veuillez spécifier un titre.`)
        }

        


        if(isNew) resApi = await createUserEvent({ 
            eventId: data.id, 
            subject: data.subject,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            location: data.location || null
        })

        else resApi = await editUserEvent({ 
                                    eventId: data.id, 
                                    subject: data.subject,
                                    startDateTime: startDateTime,
                                    endDateTime: endDateTime,
                                    location: data.location || null
                            })
        setIsCreating(() => false)

        console.log(resApi)

        if (resApi.status !== 200) {
            addAlert(resApi.data)
        } else {
            fetchData()
            setMenuVisible(() => false)
            setData(() => {})
            setBaseData(() => {})
            getAlerts().forEach(alrt => {
                deleteAlert(alrt.id)
            })
        }
    }




    const handleDeleteData = async () => {
        setIsDeleting(() => true)
        const resApi = await deleteUserEvent(data.id)
        if(resApi.status !== 200) {
            addAlert(`Une erreur s'est produite lors de la suppression.`)
        } else {
            setMenuVisible(() => false)
            fetchData()
            setData(() => {})
            setBaseData(() => {})
            addAlert(`Supprimé de l'agenda.`, "success")
        }
        setIsDeleting(() => false)

    }

    useEffect(() => {
        if (!menuVisible) setIsCreating(() => false)
    }, [menuVisible])




    return (
        <div className='w-[0px] h-[0px]'>
            <div
                onClick={(e) => {
                    if (menuVisible) {
                        //setMenuVisible(() => false)
                        e.stopPropagation()
                        e.preventDefault()
                    }
                }}
                className={cn("fixed w-full h-full flex top-[40px] left-0 backdrop-blur-[4px] ease-in-out transition-opacity duration-75 max-lg:-left-[30px] overflow-visible z-[20]", menuVisible && "opacity-100 pointer-events-auto")}></div>

            <div className="absolute  w-full flex flex-col flex-1 max-lg:h-[calc(100%_+_20px)] max-h-[700px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 atna-popup atna-popup-responsive text-[12px] p-[5px] max-w-[800px] max-lg:max-w-full">

                <div className="flex h-[20px] justify-end items-center px-[10px] w-full max-lg:hidden">
                    <div className='h-[20px] w-[20px] flex items-center justify-center cursor-pointer' onClick={() => {
                        setMenuVisible(() => false)
                    }}>
                        <div className="w-[6px] h-[6px] rounded-full bg-[rgb(191,188,182)] cursor-pointer"></div>
                    </div>
                </div>

                <div className={cn("flex flex-col gap-y-[2px] flex-1 w-full px-[15px]  pt-[10px] max-w-[800px] h-max overflow-y-auto atna-popup-scroll overflow-x-hidden pb-[20px]  max-lg:pb-[30px] justify-center")}>


                
                 

                

                  

                  

                    <div className="flex w-full gap-x-[20px] max-sm:flex-col gap-y-[5px] h-max">

                        <div className="flex flex-col w-full">
                            <h1 className={"text-[11px] text-[rgba(55,53,47,0.6)] font-medium leading-[18px] tracking-wide"}>Titre</h1>
                            <TextArea object={data} setObject={setData} value={"subject"} dataTitle={null} placeholder={""}
                                type="text"
                                required={true}
                                validation={(str) => {
                                    return { valid: true, data: null }
                                }}
                                inpClassName={cn('w-full min-h-[100px]')}
                                className={"min-w-0 w-full"} />
                        </div>
                    </div>

                    <div className="flex w-full gap-x-[20px] max-sm:flex-col gap-y-[5px] h-max">


<div className="flex flex-col w-full">
    <h1 className={"text-[11px] text-[rgba(55,53,47,0.6)] font-medium leading-[18px] tracking-wide"}>Date de début</h1>
    <DateInput object={data} setObject={setData} inputBaseRef={null} min={null} readOnly={false} objValue={"startDate"}
        type="datetime"
        className={"min-w-0 w-full"}
        required={true}
        inpClassName={cn("w-full")} />
</div>

</div>

<div className="flex w-full gap-x-[20px] max-sm:flex-col gap-y-[5px] h-max">



<div className="flex flex-col w-full">
    <h1 className={"text-[11px] text-[rgba(55,53,47,0.6)] font-medium leading-[18px] tracking-wide"}>Heure de début</h1>
    <HourInput object={data} setObject={setData} inputBaseRef={null} min={null} readOnly={false} objValue={"startHour"}
        type="datetime"
        className={"min-w-0 w-full"}
        required={true}
        inpClassName={cn("w-full")} />
</div>
<div className="flex flex-col w-full">
    <h1 className={"text-[11px] text-[rgba(55,53,47,0.6)] font-medium leading-[18px] tracking-wide"}>Heure de fin</h1>
    <HourInput object={data} setObject={setData} inputBaseRef={null} min={null} readOnly={false} objValue={"endHour"}
        type="datetime"
        className={"min-w-0 w-full"}
        required={true}
        inpClassName={cn("w-full")} />
</div>

</div>

<div className="flex w-full gap-x-[20px] max-sm:flex-col gap-y-[5px] h-max">

<div className="flex flex-col w-full">
    <h1 className={"text-[11px] text-[rgba(55,53,47,0.6)] font-medium leading-[18px] tracking-wide"}>Lieu</h1>
    <Input object={data} setObject={setData} value={"location"} dataTitle={null} placeholder={""}
        type="text"
        required={false}
        validation={(str) => {
            return { valid: true, data: null }
        }}
        inpClassName={cn('w-full')}
        className={"min-w-0 w-full"} />
</div>
</div>









                    <div className="flex items-center  gap-x-[10px] justify-end mt-[20px]">
                        <div className={cn("flex")}>
                            <div className={cn("h-[26px] rounded-[10px] transition-opacity duration-75 atna-border hover:underline font-medium text-[10px] px-[13px] flex items-center cursor-pointer", isCreating && "opacity-60 pointer-events-none")} onClick={() => {
                                setMenuVisible(() => false)
                            }}>
                                Annuler
                            </div>
                        </div>
                        <div className={cn("flex gap-x-[0px]")}>
                            <div className={cn("h-[26px] rounded-[10px] atna-border !bg-black text-wh2 font-medium text-[10px] px-[13px] flex items-center cursor-pointer")} onClick={() => {
                                // Supprimer tiers
                                if (isCreating) return;
                                setIsCreating(true)
                                handleUpdateData()
                            }}>
                                {
                                    isCreating ?
                                        <BiLoaderAlt className='animate-spin' />
                                        :
                                        isNew ? "Créer" : "Modifier"
                                }
                            </div>
                        </div>
                       {!isNew && <div className={cn("flex gap-x-[0px]")}>
                            <div className={cn("h-[26px] rounded-[10px] atna-border !bg-black text-wh2 font-medium text-[10px] px-[13px] flex items-center cursor-pointer")} onClick={() => {
                                // Supprimer tiers
                                if (isDeleting) return;
                                setIsDeleting(true)
                                handleDeleteData()
                            }}>
                                {
                                    isDeleting ?
                                        <BiLoaderAlt className='animate-spin' />
                                        :
                                        "Supprimer"
                                }
                            </div>
                        </div>}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AgendaMenu;
