import axios from "axios";
import config from "../../config";
const apiBaseUrl = config.dev ? config.devApi : config.api;

/// ---------------------------------------------------------------------------------------------------------------------------------- ///
// Projets -> Opportunites -> fiche tiers


// Récupérer la liste des projets d'une opportunité
export const fetchTiersProjets = async ({ id, idOpportunite }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching opportunities ${id} project ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération des projets pour cette opportunité.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

  // Récupérer un projet par son idProjet
export const fetchTiersOneProjets = async ({ id, idOpportunite, idProjet }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/details/${idProjet}`,
        {
          method: "GET",
          withCredentials: true,
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Fetching opportunities ${id} project ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la récupération du projet pour cet identifiant.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };

// Créer un projet pour une opportunité
export const createTiersProjets = async ({ id, idOpportunite, data }) => {
    try {
      const resApi = await axios(
        `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/create`,
        {
          method: "POST",
          withCredentials: true,
          data: {...data}
        }
      ).catch((error) => {
        console.error(
          `[API ERROR] : Creating opportunitie ${id} project ${idOpportunite} ${error}`
        );
        return {
          status: 400,
          data: "Erreur lors de la création du projet.",
        };
      });
      if (!resApi || resApi.status !== 200) {
        return {
          status: 400,
          data: "Erreur lors de la communication vers l'api.",
        };
      }
      const resData = await resApi.data;
      return resData;
    } catch (error) {
      console.log(error)
      return { status: 400, data: "Erreur lors de la communication vers l'api." };
    }
  };
  


// Modifier un projet
export const editTiersProjets = async ({ id, idOpportunite, idProjet, data = {} }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/update/${idProjet}`,
      {
        method: "POST",
        withCredentials: true,
        data,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Editing project for opportunitie ${id}/${idOpportunite} ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la modification du projet .",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};




// Supprimer un projet
export const deleteTiersProjets = async ({ id, idOpportunite, idProjet }) => {
  try {
    const resApi = await axios(
      `${apiBaseUrl}/api/tiers/${id}/opportunites/${idOpportunite}/projets/delete/${idProjet}`,
      {
        method: "DELETE",
        withCredentials: true,
      }
    ).catch((error) => {
      console.error(`[API ERROR] : Deleting project for opportunitie ${id}/${idOpportunite} ${error}`);
      return { status: 400, data: "Erreur lors de la suppression du projet." };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};

// Récupérer le catalogue
export const fetchCatalogue = async () => {
  try {
    const resApi = await axios(`${apiBaseUrl}/api/catalogue`, {
      method: "GET",
      withCredentials: true,
    }).catch((error) => {
      console.error(`[API ERROR] : Fetching catalogue ${error}`);
      return {
        status: 400,
        data: "Erreur lors de la récupération du catalogue.",
      };
    });
    if (!resApi || resApi.status !== 200) {
      return {
        status: 400,
        data: "Erreur lors de la communication vers l'api.",
      };
    }
    const resData = await resApi.data;
    return resData;
  } catch (error) {
    console.log(error);
    return { status: 400, data: "Erreur lors de la communication vers l'api." };
  }
};