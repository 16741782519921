import React from 'react';
import { cn } from '../../../../../../../utils/cn';
import { BiLoaderAlt } from 'react-icons/bi';
import Personnel from '../../../../../../../components/personnel';

const ListeTiers = ({allTiers, handleEdit, setMenuVisible, menuVisible, teamData, applyLoading = false}) => {
    return (
         <div className="w-[0px] h-[0px] z-[50]">
              <div
                onClick={(e) => {
                  if (menuVisible) {
                    //setMenuVisible(() => false)
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                className={cn(
                  "fixed  w-full h-full flex top-[40px] left-0 backdrop-blur-[4px] ease-in-out transition-opacity duration-75 max-lg:-left-[30px] overflow-visible z-[20]",
                  menuVisible && "opacity-100 pointer-events-auto"
                )}
              ></div>
        
              <div className="absolute z-[50]  w-full flex flex-col flex-1 h-max max-h-[700px]  max-lg:max-h-[calc(100vh_-_0px)] max-lg:pt-[20px] max-lg:mt-[40px] max-lg:h-full  left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 atna-popup atna-popup-responsive text-[12px] px-[5px] pb-[0px] max-w-[800px] max-lg:max-w-full">
                <div className="flex h-[20px] justify-end items-center mt-[10px] px-[10px] w-full max-lg:hidden">
                  <div
                    className="h-[20px] w-[20px] flex shrink-0 items-center justify-center cursor-pointer"
                    onClick={() => {
                      setMenuVisible(() => false);
                    }}
                  >
                    <div className="w-[6px] h-[6px] rounded-full bg-[rgb(191,188,182)] cursor-pointer"></div>
                  </div>
                </div>
        
                <div
                  style={{
                    scrollbarGutter: "stable",
                  }}
                  className={cn(
                    "flex flex-col gap-y-[2px] flex-1 w-full px-[15px]  pt-[10px] max-w-[800px] max-h-[calc(100%_-_20px)]  max-lg:max-h-max overflow-y-auto atna-drop-menu-scroll overflow-x-hidden pb-[20px]  max-lg:pb-[30px] "
                  )}>



                  <div className="atna-details-table-inner overflow-x-auto overflow-y-auto atna-drop-menu-scroll">
                        <table className='atna-details-table !min-w-[700px] max-h-[500px]'>
                            <thead>
                                <tr className='atna-hd-table'>
                                    <th className='w-[50px]'>Raison Sociale</th>
                                    <th>Adresse</th>
                                    <th>Titulaire de votre équipe</th>
                                </tr>
                            </thead>
                            <tbody>
                                      {allTiers.map((tiers, tiersIndex) => {
                                        return (
                                           <tr key={`listTiersOnSec_${tiers.idTIers}`} >
                                            <th>{tiers.raisonSociale}</th>
                                            <th>{tiers.adresse}</th>
                                            <th className='relative'>
                                              <Personnel id={tiers.idTitulaire} hidden/>
                                            </th>
                                           </tr>
                                        )
                                    })}
                                </tbody>
                        </table>
                    </div>


                    <div className="flex justify-center my-[20px]">
                        <h1 className="text-[13px] px-[5px] text-[rgba(55,53,47,0.5)] font-medium">
                                    {
                                      allTiers.length > 0 ? 
                                      (
                                        `${allTiers.length} titulaires seront écrasés.`
                                      )
                                      :
                                      (
                                        `Aucune titulaire ne sera écrasé.`
                                      )
                                    }
                         </h1>
                    </div>

                    <div className="flex justify-end mt-[10px]">
                            <div
                              className={cn(
                                "h-[26px] rounded-[10px] atna-border !bg-black text-white font-semibold text-[10px] px-[13px] flex items-center cursor-pointer"
                              )}
                              onClick={() => {
                                 handleEdit()
                              }}
                            >
                              {
                                applyLoading ? 
                                <BiLoaderAlt className='animate-spin'/>
                                :
                                "Appliquer"
                              }
                            </div>
                          </div>


                    </div>
            </div>
        </div>
    );
}

export default ListeTiers;
