import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { editEquipeSecteurAffectation, fetchEquipeSecteurAffectation, fetchEquipeSecteurs, fetchEquipeUsers } from '../../../../../../../utils/atna/equipes/equipe';
import Input from '../../../../../../../components/inputs/input'
import { BiLoaderAlt } from 'react-icons/bi';
import { useAlerts } from '../../../../../../../utils/hooks/useAlert';
import { cn } from '../../../../../../../utils/cn';
import { editCellule, editUser, editUserAcces, fetchCellules, fetchSecteurs } from '../../../../../../../utils/atna';
import { useGlobalState } from '../../../../../../../utils/hooks/useGlobalState';
import InputSelect from '../../../../../../../components/inputs/inputSelect';
import { debounce } from 'lodash';

const ListeCellules = () => {


    const [cellules, setCellules] = useState([])
    const [secteurs, setSecteurs] = useState([])
    const [secLoading, setSecLoading] = useState(true)
    const [isMenuVisible, setIsMenuVisible] = useState(false)
        const [searchTerm, setSearchTerm] = useState('');

    const getDataSecteurs = useCallback(async () => {
        const resApi = await fetchSecteurs()
        setSecLoading(() => false)
        if (resApi.status === 200) {
            return setSecteurs(() => {
                return [...resApi.data]
            })
        }
    }, [])
    



        const secteursOptions = useMemo(() => {
            return [...secteurs.map((sec) => {
                return {label: sec.idCommercial, value: sec.idCommercial}
            })]
        }, [secteurs])


    const getDataCellules = useCallback(async () => {
        const resApi = await fetchCellules({
            query: searchTerm
        })
        setSecLoading(() => false)
        if (resApi.status === 200) {
            return setCellules(() => {
                return [...resApi.data]
            })
        }
    }, [searchTerm])

         const debouncedSetSearchTerm = useMemo(() => debounce(setSearchTerm, 200), [setSearchTerm]);


    useEffect(() => {
        getDataCellules()
    }, [getDataCellules])


    useEffect(() => {
        getDataSecteurs()
    }, [getDataSecteurs])


  


    return (
        <div className={cn("flex flex-col w-full pb-[40px]")}>


<div className="mt-[20px] flex justify-center ">
       <div className="atna-search-bar flex items-center w-[90%] max-w-[500px] rounded-[5px] bg-[rgba(0,0,0,0.5)] h-[26px] shrink-0 grow-0">
                <input
                    type="text"
                    className='h-full w-full border-none outline-none bg-transparent px-[20px] text-[12px] placeholder:text-[rgba(0,0,0,0.5)] placeholder:text-[11px] font-normal py-[5px] tracking-wide'
                    placeholder='Rechercher par code de cellule'
                    onChange={(e) => {
                        debouncedSetSearchTerm(e.target.value);
                    }}
                />
            </div>
       </div>

            {
            secLoading ?
            (
                   <div className="flex-[1_1_auto]">
                                        <div className="w-full mt-[80px] flex justify-center items-center h-1/3">
                
                                            <BiLoaderAlt className='animate-spin text-[15px] text-black/50' />
                
                                        </div>
                   </div>
            )
            :
                <div className='w-full h-full flex justify-center mt-[20px] mb-[50px]'>
                    <div className="atna-details-table-inner !overflow-x-visible">
                        <table className='atna-details-table'>
                            <thead>
                                <tr className='atna-hd-table'>
                                    <th>Rg. Cellules</th>
                                    <th>Secteur</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    cellules.map((cel, celIndex) => {
                                        return (
                                            <CelluleMapped key={`settingsManagerCelluels_${cel.rgCellules}`} 
                                            isMenuVisible={isMenuVisible} setIsMenuVisible={setIsMenuVisible}
                                            secteursOptions={secteursOptions}
                                            fetchData={getDataCellules} cellule={cel} celluleIndex={celIndex} setCellules={setCellules} cellules={cellules}/>
                                             )
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
        }
        </div>
    );
}


const CelluleMapped = ({ cellule, fetchData, isMenuVisible, setIsMenuVisible, secteursOptions }) => {
    const [celluleData, setCelluleData] = useState(cellule)
    const [applyLoading, setApplyLoading] = useState(false)
    const {addAlert, getAlerts, deleteAlert} = useAlerts()
    const [listSecMenuVisible, setListSecMenuVisible] = useState(false)
    const [allTiers, setAllTiers] = useState([])

    useEffect(() => {
        setIsMenuVisible(() => {
            return listSecMenuVisible
        })
    }, [listSecMenuVisible])


    const handleSave = async() => {
        setApplyLoading(() => true)
        const resApi = await editCellule({code: celluleData.rgCellules, secteur: celluleData.idCommercial})
        setApplyLoading(() => false)
        if(resApi.status !== 200) {
            addAlert(`Erreur lors de la modification de la cellule.`)
        } else {
            setListSecMenuVisible(() => false)
            fetchData()
        }
    }



    return (
        <>
    <tr className={cn('hover:bg-black/[0.01]')}>
        <th>
            {celluleData.rgCellules}
        </th>
        <th>
           <InputSelect
           object={celluleData}
           type="text"
           setObject={setCelluleData}
          value='idCommercial'
          options={secteursOptions}
            validation={(str) => {
                return {valid: true, data: str}
            }}
            className={"w-full h-full bg-transparent"}
            inpClassName={"w-full h-full rounded-none bg-transparent"}
            />
        </th>
        <th className='text-center align-middle text-[12px] font-medium w-[100px] hover:bg-black/[0.02] active:bg-black/[0.05]'
                onClick={() =>  {
                    if(!applyLoading) handleSave()
                }}>
            {
                applyLoading ?
                (
                    <BiLoaderAlt className='animate-spin w-full text-center text-black'/> 
                )
                :
                (
                    'Appliquer'
                )
            }
        </th>
    </tr>
    </>
    )
}

export default ListeCellules;
