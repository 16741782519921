import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { editEquipeSecteurAffectation, editUserBareme, fetchEquipeSecteurAffectation, fetchEquipeSecteurs, fetchEquipeUsers } from '../../../../../../../utils/atna/equipes/equipe';
import Input from '../../../../../../../components/inputs/input'
import { BiLoaderAlt } from 'react-icons/bi';
import { useAlerts } from '../../../../../../../utils/hooks/useAlert';
import ListeTiers from './listeTiers';
import { cn } from '../../../../../../../utils/cn';
import { editUser, editUserAcces } from '../../../../../../../utils/atna';
import Personnel from '../../../../../../../components/personnel';

const Users = ({ teamData  }) => {


    const [users, setUsers] = useState([])
    const [secLoading, setSecLoading] = useState(true)
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const getDataTeamUsers = useCallback(async () => {
        if (!teamData) return;
        const resApi = await fetchEquipeUsers({ id: teamData.id })
        setSecLoading(() => false)
        if (resApi.status === 200) {
            return setUsers(() => {
                return [...resApi.data]
            })
        }
    }, [teamData])


    useEffect(() => {
        getDataTeamUsers()
    }, [getDataTeamUsers])


  


    return (
        <div className={cn("flex flex-col w-full pb-[40px]")}>
            {
            secLoading ?
            (
                   <div className="flex-[1_1_auto]">
                                        <div className="w-full mt-[80px] flex justify-center items-center h-1/3">
                
                                            <BiLoaderAlt className='animate-spin text-[15px] text-black/50' />
                
                                        </div>
                   </div>
            )
            :
            users.length > 0 ? (
                <div className='w-full h-full flex justify-center mt-[20px] mb-[50px]'>
                    <div className="atna-details-table-inner !overflow-x-visible">
                        <table className='atna-details-table'>
                            <thead>
                                <tr className='atna-hd-table'>
                                    <th className='w-[50px]'>Utilisateur</th>
                                    <th>Barême</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    users.map((us, usIndex) => {
                                        return (
                                            <UserMapped key={`settingsManagerUsers_${usIndex}`} 
                                            isMenuVisible={isMenuVisible} setIsMenuVisible={setIsMenuVisible}
                                            fetchData={getDataTeamUsers} teamData={teamData} user={us} userIndex={usIndex} setUsers={setUsers} users={users}/>
                                             )
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className='w-full flex justify-center text-[13px] text-[rgb(133,132,128)] font-normal mt-[50px]'>
                    Aucun utilisateur.
                </div>
            )}
        </div>
    );
}


const UserMapped = ({ user, userIndex, teamData, fetchData, isMenuVisible, setIsMenuVisible }) => {
    const [userData, setUserData] = useState(user)
    const [applyLoading, setApplyLoading] = useState(false)
    const {addAlert, getAlerts, deleteAlert} = useAlerts()
    const [listSecMenuVisible, setListSecMenuVisible] = useState(false)
    const [allTiers, setAllTiers] = useState([])

    useEffect(() => {
        setIsMenuVisible(() => {
            return listSecMenuVisible
        })
    }, [listSecMenuVisible])


    const handleSave = async() => {
        setApplyLoading(() => true)
        const resApi = await editUserBareme({id: user.id, bareme: userData.bareme})
        setApplyLoading(() => false)
        if(resApi.status !== 200) {
            addAlert(`Erreur lors de la modification du barême.`)
        } else {
            setListSecMenuVisible(() => false)
            fetchData()
        }
    }



    return (
        <>
    <tr className={cn('hover:bg-black/[0.01]')}>
        <th>
            <Personnel id={userData.id}/>
        </th>
        <th>
           <Input
           object={userData}
           type="number"
           setObject={setUserData}
            value='bareme'
            validation={(str) => {
                return {valid: true, data: str}
            }}
            className={"w-full h-full bg-transparent"}
            inpClassName={"w-full h-full rounded-none bg-transparent"}
            />
        </th>
        <th className='text-center align-middle text-[12px] font-medium w-[100px] hover:bg-black/[0.02] active:bg-black/[0.05]'
                onClick={() =>  {
                    if(!applyLoading) handleSave()
                }}>
            {
                applyLoading ?
                (
                    <BiLoaderAlt className='animate-spin w-full text-center text-black'/> 
                )
                :
                (
                    'Appliquer'
                )
            }
        </th>
    </tr>
    </>
    )
}

export default Users;
