import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { 
    getActiviteActions, 
    getActivitePriorites, 
    getDataCodesNAF, 
    getDataCommunes, 
    getDataSecteurs, 
    getPersonnel, 
    getTypesTaches,
    getUsers,
    fetchUsersVisible
} from '../atna';
import {useAuth} from "./useAuth"

// Créez le contexte
const DataContext = createContext();

// Fournisseur de contexte
const GlobalStateProvider = ({ children }) => {
    const [listCodeNaf, setListCodeNaf] = useState([]);
    const [listCommunes, setListCommunes] = useState([]);
    const [listSecteurs, setListSecteurs] = useState([]);
    const [listPersonnel, setListPersonnel] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [activiteActions, setActiviteActions] = useState([]);
    const [activitePriorites, setActivitePriorites] = useState([]);
    const [typesTaches, setTypesTaches] = useState([]);
    const [usersVisible, setUsersVisible] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const {user} = useAuth()

    const listStructure = useMemo(() => [
        { value: "Repro-IT", label: "Repro-IT" },
        { value: "Data-IT", label: "Data-IT" },
        { value: "Phone-IT", label: "Phone-IT" },
        { value: "Ident-IT", label: "Ident-IT" },
    ], []);

      useEffect(() => { 
            const getUsersVisible = async() => {
                const resApi = await fetchUsersVisible()
                if(resApi.status === 200) {
                setUsersVisible(() => (
                    listPersonnel?.length > 0 ? [
                        ...([...resApi.data.filter(u => listPersonnel.find(p => p.id === u.id))]||[]).map(u => {
                            return listPersonnel.find(p => p.id === u.id)
                        })
                    ] 
                    :
                    []
                )
                )
                }
            }
            getUsersVisible()
      }, [listPersonnel]);

    const fetchData = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const [
                codesNAF, 
                communes, 
                secteurs, 
                personnel, 
                users,
                activiteActions, 
                activitePriorites,
                typesTaches
            ] = await Promise.all([
                getDataCodesNAF(),
                getDataCommunes(),
                getDataSecteurs(),
                getPersonnel(),
                getUsers(),
                getActiviteActions(),
                getActivitePriorites(),
                getTypesTaches()
            ]);

            if(codesNAF) setListCodeNaf(codesNAF);
            if(communes) setListCommunes(communes);
            if(secteurs) setListSecteurs(secteurs);
            if(personnel) setListPersonnel(personnel);
            if(users) setAllUsers(users);
            if(activiteActions) setActiviteActions(activiteActions);
            if(activitePriorites) setActivitePriorites(activitePriorites);
            if(typesTaches) setTypesTaches(typesTaches)
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData, user.isAuth]);

    // Mémorisation des listes pour éviter des recalculs inutiles
    const memoizedListCodeNaf = useMemo(() => listCodeNaf, [listCodeNaf]);
    const memoizedListCommunes = useMemo(() => listCommunes, [listCommunes]);
    const memoizedListSecteurs = useMemo(() => listSecteurs, [listSecteurs]);
    const memoizedListPersonnel = useMemo(() => listPersonnel, [listPersonnel]);
    const memoizedListUsers = useMemo(() => allUsers, [allUsers]);
    const memoizedActiviteActions = useMemo(() => activiteActions, [activiteActions]);
    const memoizedActivitePriorites = useMemo(() => activitePriorites, [activitePriorites]);
    const memoizedTypesTaches = useMemo(() => typesTaches, [typesTaches]);
    const memoizedUsersVisible = useMemo(() => usersVisible, [usersVisible]);

    const memoizedPeriodes = useMemo(() => {
        return [
            // Trimestres
            { label: "T1", value: "T1" },
            { label: "T2", value: "T2" },
            { label: "T3", value: "T3" },
            { label: "T4", value: "T4" },
    
            // Quadrimestres
            { label: "Q1", value: "Q1" },
            { label: "Q2", value: "Q2" },
            { label: "Q3", value: "Q3" },
    
            // Semestres
            { label: "S1", value: "S1" },
            { label: "S2", value: "S2" }
        ];
    }, []);

    const value = useMemo(() => ({
        listCodeNaf: memoizedListCodeNaf,
        listCommunes: memoizedListCommunes,
        listSecteurs: memoizedListSecteurs,
        listPersonnel: memoizedListPersonnel,
        allUsers: memoizedListUsers,
        activiteActions: memoizedActiviteActions,
        activitePriorites: memoizedActivitePriorites,
        listStructure,  // listStructure est déjà mémorisé
        typesTaches: memoizedTypesTaches,
        allUsersVisible: memoizedUsersVisible,
        fetchData,
        loading,
        error,
        arrPeriodes: memoizedPeriodes
    }), [
        memoizedListCodeNaf,
        memoizedListCommunes,
        memoizedListSecteurs,
        memoizedListPersonnel,
        memoizedListUsers,
        memoizedActiviteActions,
        memoizedActivitePriorites,
        memoizedTypesTaches,
        memoizedUsersVisible,
        memoizedPeriodes,
        listStructure,
        fetchData,
        loading,
        error
    ]);

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
};

// Custom hook for using the data context
const useGlobalState = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error("useGlobalState must be used within a GlobalStateProvider");
    }
    return context;
};

export { GlobalStateProvider, useGlobalState };
