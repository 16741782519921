import React, { useEffect, useState, useRef } from 'react';
import { fetchTeams, editTeam } from '../../../../utils/atna';
import { useGlobalState } from '../../../../utils/hooks/useGlobalState';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { cn } from '../../../../utils/cn';
import Personnel from '../../../../components/personnel';

const TeamNode = ({ team, children, onDragStart, onDrop, onRemoveParent, listPersonnel,
  onLevelUp, 
  onLevelDown  }) => {
  const [isOpen, setIsOpen] = useState(false); // État pour suivre si le contenu est ouvert
  const contentRef = useRef(null); // Référence au conteneur des enfants pour calculer sa hauteur

  

  const toggleOpen = () => setIsOpen((prev) => !prev); // Inverse l'état ouvert/fermé

  return (
    <div
      className="team-node border-[1px] border-black/10 rounded-[10px] px-[30px] pl-[50px] py-[15px] mt-[20px]"
      onDrop={(e) => onDrop(e, team)}
      onDragOver={(e) => e.preventDefault()} // Permet de déposer les éléments
      draggable
      onDragStart={(e) => onDragStart(e, team)}
      onClick={toggleOpen}
    >

      <div className="team-header flex justify-between items-center relative">

         {!team.idParent && (<div className="absolute -left-[40px] -top-[15px] w-[25px] h-[calc(100%_+_30px)] rounded-l-[10px] flex flex-col items-center justify-center"
         >
               <BiChevronUp className='font-bold text-[20px] text-black/50' 
         onClick={(e) => {
          e.stopPropagation();
          onLevelDown(team);
         }}/>
               <h3 className='font-bold text-[12px] text-black/40'>{team.niveau}</h3>
               <BiChevronDown  className='font-bold text-[20px] text-black/50' 
         onClick={(e) => {
          e.stopPropagation();
          onLevelUp(team);
         }}/>
          </div>)}
        <div>
          <h3 className="font-bold text-[14px] text-black/90">{team.nom || "Non défini"}</h3>
          <p className="text-[13px] text-black/50 font-medium">
            <Personnel id={team.idManager}/>
          </p>
        </div>
        <div className="flex items-center">
          {team.idParent && (
            <div
              className="h-[28px] rounded-[10px] atna-border !bg-white text-black font-medium text-[10.5px] px-[20px] flex items-center cursor-pointer hover:underline mr-3"
              onClick={() => onRemoveParent(team)}
            >
              Retirer parent
            </div>
          )}
          {/* Flèche pour ouvrir/fermer */}
          <div
            className="cursor-pointer text-[16px] font-bold"
          >
            {children && children.length > 0 && (
            <BiChevronDown className={cn("transition-all duration-150", !isOpen && "-rotate-90")} />
            )}
          </div>
        </div>
      </div>
      {/* Contenu des enfants avec animation */}
      <div
        className="transition-[max-height] duration-300 ease-in-out overflow-hidden"
        style={{
          maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : "0px",
        }}
        ref={contentRef}
      >
        {children && children.length > 0 && (
          <div className="children pl-6 mt-2">
            {children.map((child) => (
              <TeamNode
                listPersonnel={listPersonnel}
                key={child.id}
                team={child}
                children={child.children}
                onDragStart={onDragStart}
                onDrop={onDrop}
                onRemoveParent={onRemoveParent}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const CrmMenuTeamsView = () => {
  const [teams, setTeams] = useState([]);
  const [draggedTeam, setDraggedTeam] = useState(null);

  const { listPersonnel } = useGlobalState();

  const fetchData = async () => {
    const resApi = await fetchTeams();
    if (resApi.status === 200) {
      setTeams(resApi.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const buildHierarchy = (teams, parentId = null) => {
    return teams
      .sort((a, b) => b.niveau - a.niveau) // Trie les équipes par niveau
      .filter((team) => team.idParent === parentId)
      .map((team) => ({
        ...team,
        children: buildHierarchy(teams, team.id),
      }));
  };

  const hierarchy = buildHierarchy(teams);

  const handleDragStart = (e, team) => {
    setDraggedTeam(team); // Assure que l'élément déplacé est bien enregistré
  };

  const handleDrop = (e, parentTeam) => {
    if (!draggedTeam) return; // Si aucun élément n'est en cours de déplacement
    if (draggedTeam.id === parentTeam.id) return; // Empêche de glisser sur lui-même

    // Mise à jour directe de l'élément déplacé
    const updatedTeam = { ...draggedTeam, idParent: parentTeam.id };
    editTeam({ id: draggedTeam.id, data: updatedTeam })
      .then(() => fetchData()) // Recharge les données après la mise à jour
      .catch((err) => alert("Erreur lors de la mise à jour du parent."));
    setDraggedTeam(null); // Réinitialise l'élément déplacé
  };

  const handleRemoveParent = (team) => {
    const updatedTeam = { ...team, idParent: null }; // Supprime le parent
    editTeam({ id: team.id, data: updatedTeam })
      .then(() => fetchData()) // Recharge les données après la mise à jour
      .catch((err) => alert("Erreur lors de la suppression du parent."));
  };

  const handleLevelUp = (team) => {
    const updatedTeam = { ...team, niveau: Math.max(0, team.niveau - 1) }; // Réduit le niveau avec un minimum de 0
    editTeam({ id: team.id, data: updatedTeam })
      .then(() => fetchData())
      .catch((err) => alert("Erreur lors de la mise à jour du niveau."));
  };

  const handleLevelDown = (team) => {
    const updatedTeam = { ...team, niveau: team.niveau + 1 }; // Augmente le niveau
    editTeam({ id: team.id, data: updatedTeam })
      .then(() => fetchData())
      .catch((err) => alert("Erreur lors de la mise à jour du niveau."));
  };

  return (
    <div className="team-hierarchy p-5">
      {hierarchy.map((team) => (
        <TeamNode
          listPersonnel={listPersonnel}
          key={team.id}
          team={team}
          children={team.children}
          onDragStart={handleDragStart}
          onDrop={handleDrop}
          onRemoveParent={handleRemoveParent}
          onLevelUp={handleLevelUp}
          onLevelDown={handleLevelDown}
        />
      ))}
    </div>
  );
};

export default CrmMenuTeamsView;