import React, { useEffect, useMemo, useState } from 'react';
import { editTeam, fetchUserServiceEquipes } from '../../../../../../../utils/atna';
import { BiArrowFromBottom, BiArrowToTop, BiLinkAlt, BiLinkExternal, BiLoaderAlt, BiShareAlt, BiUnlink } from 'react-icons/bi';
import { cn } from '../../../../../../../utils/cn';
import Personnel from '../../../../../../../components/personnel';
import { useNavigate, useParams } from 'react-router-dom';
import { useGlobalState } from '../../../../../../../utils/hooks/useGlobalState';
import InputSelect from '../../../../../../../components/inputs/inputSelect';
import {LuArrowUpRight} from 'react-icons/lu'
import { useAlerts } from '../../../../../../../utils/hooks/useAlert';

const ListeEquipes = ({serviceData}) => {
    const [equipes, setEquipes] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const {allUsers} = useGlobalState()

         async function fetchData() {
                const resApi = await fetchUserServiceEquipes({id: serviceData.id})
                setLoading(() => false)
                if(resApi.status === 200) {
                    setEquipes(() => {
                    return [...resApi.data]
                })
     

            }
        }
        useEffect(() => {
            fetchData()
        }, [])


            const usersOptions = useMemo(() => {
                return [...allUsers.map(u => {
                    return { value: u.id, label: u.nomComplet }
                })]
            }, [allUsers])
        
        


    return (
         <div className={cn("flex flex-col w-full pb-[40px]")}>
                    {
                    loading ?
                    (
                           <div className="flex-[1_1_auto]">
                                                <div className="w-full mt-[80px] flex justify-center items-center h-1/3">
                        
                                                    <BiLoaderAlt className='animate-spin text-[15px] text-black/50' />
                        
                                                </div>
                           </div>
                    )
                    :
                    equipes.length > 0 ? (
                        <div className='w-full h-full flex justify-center mt-[20px] mb-[50px]'>
                            <div className="atna-details-table-inner !overflow-x-visible">
                                <table className='atna-details-table'>
                                    <thead>
                                        <tr className='atna-hd-table'>
                                            <th className='w-[50px]'>Nom</th>
                                            <th>Manager</th>
                                        </tr>
                                    </thead>
                                    <tbody>
        
                                        {
                                            equipes.map((eq, eqIndex) => {
                                                return (
                                                           <EquipeData eq={eq} eqIndex={eqIndex} usersOptions={usersOptions} fetchData={fetchData}
                                                           />
                                                     )
                                            })
                                        }
        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className='w-full flex justify-center text-[13px] text-[rgb(133,132,128)] font-normal mt-[50px]'>
                            Aucune équipe appartenant à ce service.
                        </div>
                    )}
                </div>
    );
}

const EquipeData = ({eq, eqIndex, usersOptions, fetchData}) =>{
    const navigate = useNavigate()
    const [eqData, setEqData] = useState(eq)
    const {addAlert} = useAlerts()
    const [applyLoading, setApplyLoading] = useState(false)


    const handleSave = async() => {
        setApplyLoading(() => true)
        const resApi = await editTeam({id: eq.id, data: eqData})
        setApplyLoading(() => false)
        if(resApi.status !== 200) {
            addAlert("Impossible de modifier l'équipe.")
        } else {
            fetchData()
        }
    }


    return (
        <tr 
        className='hover:bg-black/[0.02] cursor-pointer'
        >
            <th>
                {eq.nom}
            </th>
            <th>
            <InputSelect
                options={
                    usersOptions
                }
                value={`idManager`}
                object={eqData}
                setObject={setEqData}
                className={'bg-transparent rounded-none'}
            />
            </th>
            <th className='text-center align-middle text-[12px] font-medium w-[100px] hover:bg-black/[0.02] active:bg-black/[0.05]'
            onClick={() => {
              handleSave()
        }}>
              {
                             applyLoading ?
                             (
                                 <BiLoaderAlt className='animate-spin w-full text-center'/> 
                             )
                             :
                             (
                                 'Appliquer'
                             )
                         }
        </th>
        <th className='text-center align-middle text-[12px] font-medium !min-w-0 !w-[50px] hover:bg-black/[0.02] active:bg-black/[0.05]'
            onClick={() => {
            navigate(`/crm/parametres/equipe/${eq.id}`)
        }}>
             <LuArrowUpRight className='w-full text-[13px]'/>
        </th>
        </tr>
    )
}

export default ListeEquipes;
