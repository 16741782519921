import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { editTeam, fetchTeam, fetchUserTeams } from '../../../../../../utils/atna';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts } from '../../../../../../utils/hooks/useAlert';
import { useAuth } from '../../../../../../utils/hooks/useAuth';
import { cn } from '../../../../../../utils/cn';
import { fetchEquipeSecteurs, fetchEquipeUsers } from '../../../../../../utils/atna/equipes/equipe';
import { BiLoaderAlt } from 'react-icons/bi';
import Personnel from '../../../../../../components/personnel';
import Secteurs from './components/secteurs';
import Users from './components/users';

const CrmParametresEquipeView = ({currentView}) => {

    const [teamData, setTeamData] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const { addAlert } = useAlerts()
    const [isEdited, setIsEdited] = useState(false);
    const [loading, setLoading] = useState(true)
    const { user } = useAuth()
    const navigate = useNavigate()
    const [subView, setSubView] = useState("secteurs")
    const { pageState } = useParams()


    useEffect(() => {
        if (user.permLoaded && !user.niveau) return navigate('/crm')
    }, [user])







    async function fetchData() {
        let resApi;
        if(pageState) resApi = await fetchTeam({id: pageState})
        else resApi = await fetchUserTeams()
        if(resApi.status === 200) {
            setTeamData(() => {
                return { ...resApi.data }
            })
        }
        setLoading(() => false)
    }
    useEffect(() => {
        fetchData()
    }, [])



        // Team members
        const [users, setUsers] = useState([])

        const fetchTeamUsers = useCallback(async () => {
            if(!teamData) return;
            const resApi = await fetchEquipeUsers({id: teamData.id})
            if (resApi.status === 200) {
                setUsers(resApi.data)
            }
        }, [teamData]
    )
        useEffect(() => {
            fetchTeamUsers()
        }, [fetchTeamUsers])

  

        const allSubTabs = useMemo(() => {
            let allTabs = [{ label: "Secteurs", id: "secteurs" }, {label: 'Utilisateurs', id: 'users'}]
            if(currentView === "service" || currentView === "entreprise") {
                allTabs.push({ label: "Cellules", id: "cellules" })
                allTabs.push({ label: "Communes", id: "communes" })
            }
            return allTabs
        }, [currentView])




    return (
        <div className='flex-1 flex flex-col w-full'>


            {
                loading ? (
                    <div className="flex-[1_1_auto]">
                        <div className="w-full mt-[80px] flex justify-center items-center h-1/3">

                            <BiLoaderAlt className='animate-spin text-[15px] text-black/50' />

                        </div>
                    </div>
                )
                    :
                    !teamData ? (
                        <div className="flex-[1_1_auto]">
                            <div className="w-full mt-[80px] flex justify-center items-center h-1/3">
                                <h1 className="text-[13px] px-[5px] text-[rgba(55,53,47,0.5)] font-medium">
                                    Aucune équipe
                                </h1>
                            </div>
                        </div>
                    )
                        :
                        (
                            <div className="flex-1 flex flex-col w-full py-[20px] px-[10px]">
                                <h1 className='text-[14px] font-medium text-black/80'>
                                    {teamData.nom}
                                </h1>
                                <h2 className='text-[13px] font-medium text-black/50'>
                                    <Personnel id={teamData.idManager} upper/>
                                </h2>


                                <div className="h-[1px] w-full bg-black/[0.04] my-[10px]"></div>


                                <div className="flex shrink-0 items-center atna-invisible-scrollbar gap-x-[0px] border-b-[1px] border-b-black/5 mt-[10px]  overflow-x-auto max-w-[400px]">
                                    {allSubTabs.map(tp => {
                                        return (
                                            <div
                                                key={tp.id}
                                                onClick={() => {
                                                    setSubView(() => tp.id)
                                                }}
                                                className={cn("text-[12px] w-max font-medium px-[20px] h-[30px]  flex items-center justify-center cursor-pointer text-nowrap ",
                                                    subView === tp.id ? "bg-black/5" : " hover:bg-black/[0.02]"
                                                )}>
                                                {tp.label}
                                            </div>
                                        )
                                    })
                                    }
                                </div>

                                {
                                    subView === "secteurs" ? (
                                        <Secteurs
                                        users={users}
                                        teamData={teamData}/>
                                    )
                                    :
                                    subView === "users" && (
                                        <Users
                                        users={users}
                                        teamData={teamData}/>
                                    )
                                }


                                
                            </div>
                        )
            }
        </div>
    );
}

export default CrmParametresEquipeView;
