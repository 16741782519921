import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { editEquipeSecteurAffectation, fetchEquipeSecteurAffectation, fetchEquipeSecteurs } from '../../../../../../../utils/atna/equipes/equipe';
import InputSelect from '../../../../../../../components/inputs/inputSelect'
import { BiLoaderAlt } from 'react-icons/bi';
import { useAlerts } from '../../../../../../../utils/hooks/useAlert';
import ListeTiers from './listeTiers';
import { cn } from '../../../../../../../utils/cn';

const Secteurs = ({ teamData, users }) => {


    const [secteurs, setSecteurs] = useState([])
    const [secLoading, setSecLoading] = useState(true)
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const getDataTeamSecteurs = useCallback(async () => {
        if (!teamData) return;
        const resApi = await fetchEquipeSecteurs({ id: teamData.id })
        setSecLoading(() => false)
        if (resApi.status === 200) {
            return setSecteurs(() => {
                return [...resApi.data]
            })
        }
    }, [teamData])


    useEffect(() => {
        getDataTeamSecteurs()
    }, [getDataTeamSecteurs])


    const usersOptions = useMemo(() => {
        return [...users.map(u => {
            return { value: u.id, label: u.nomComplet }
        })]
    }, [users])


    return (
        <div className={cn("flex flex-col w-full pb-[40px]")}>
            {
            secLoading ?
            (
                   <div className="flex-[1_1_auto]">
                                        <div className="w-full mt-[80px] flex justify-center items-center h-1/3">
                
                                            <BiLoaderAlt className='animate-spin text-[15px] text-black/50' />
                
                                        </div>
                   </div>
            )
            :
            secteurs.length > 0 ? (
                <div className='w-full h-full flex justify-center mt-[20px] mb-[50px]'>
                    <div className="atna-details-table-inner !overflow-x-visible">
                        <table className='atna-details-table'>
                            <thead>
                                <tr className='atna-hd-table'>
                                    <th className='w-[50px]'>Secteur</th>
                                    <th>Commercial</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    secteurs.map((sec, secIndex) => {
                                        return (
                                            <SecteurMapped key={`settingsManagerSecs_${secIndex}`} 
                                            isMenuVisible={isMenuVisible} setIsMenuVisible={setIsMenuVisible}
                                            fetchData={getDataTeamSecteurs} teamData={teamData} sec={sec} secIndex={secIndex} secteurs={secteurs} setSecteurs={setSecteurs} usersOptions={usersOptions}/>
                                             )
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className='w-full flex justify-center text-[13px] text-[rgb(133,132,128)] font-normal mt-[50px]'>
                    Aucun secteur affecté.
                </div>
            )}
        </div>
    );
}


const SecteurMapped = ({ sec, secIndex, secteurs, setSecteurs, usersOptions, teamData, fetchData, isMenuVisible, setIsMenuVisible }) => {
    const [secData, setSecData] = useState(sec)
    const [applyLoading, setApplyLoading] = useState(false)
    const {addAlert, getAlerts, deleteAlert} = useAlerts()
    const [listSecMenuVisible, setListSecMenuVisible] = useState(false)
    const [allTiers, setAllTiers] = useState([])

    useEffect(() => {
        setIsMenuVisible(() => {
            return listSecMenuVisible
        })
    }, [listSecMenuVisible])


    const handleApplyIc = async() => {
        setApplyLoading(() => true)
        const newIc = secData[`${teamData.ic_type}`]
        const resApi = await editEquipeSecteurAffectation({id: teamData.id,idSecteur: sec.idSecteur, id_ic: newIc})
        setApplyLoading(() => false)
        if(resApi.status !== 200) {
            addAlert(`Erreur lors de la modification du titulaire de ${sec.idSecteur}.`)
        } else {
            setListSecMenuVisible(() => false)
            fetchData()
        }
    }

    const fetchTiersOnSecteur = async() => {
        getAlerts().forEach((alert) => {
            deleteAlert(alert.id)
        })
        setApplyLoading(() => true)
        const newIc = secData[`${teamData.ic_type}`]
        if(!newIc) return handleApplyIc()
        const resApi = await fetchEquipeSecteurAffectation({id: teamData.id,idSecteur: sec.idSecteur, id_ic: newIc})
        setApplyLoading(() => false)
        if(resApi.status !== 200) {
            addAlert(`Erreur lors de la récupération des fiches du secteur ${sec.idSecteur}.`)
        } else {
             setAllTiers(() => {
                return [...resApi.data]
             })
             setListSecMenuVisible(() => true)
        }

    }

    return (
        <>
        {
            listSecMenuVisible && (
                <ListeTiers allTiers={allTiers} applyLoading={applyLoading} teamData={teamData} menuVisible={listSecMenuVisible} setMenuVisible={setListSecMenuVisible} handleEdit={handleApplyIc}/>
            )
        }
    <tr className={cn('hover:bg-black/[0.01]')}>
        <th>
            {sec.idSecteur}
        </th>
        <th>
            <InputSelect
                options={
                    usersOptions
                }
                value={`${teamData.ic_type}`}
                object={secData}
                setObject={setSecData}
                className={'bg-transparent rounded-none'}
            />
        </th>
        <th className='text-center align-middle text-[12px] font-medium w-[100px] hover:bg-black/[0.02] active:bg-black/[0.05]'
                onClick={() =>  {
                    if(!applyLoading) fetchTiersOnSecteur()
                }}>
            {
                applyLoading ?
                (
                    <BiLoaderAlt className='animate-spin w-full text-center'/> 
                )
                :
                (
                    'Appliquer'
                )
            }
        </th>
    </tr>
    </>
    )
}

export default Secteurs;
