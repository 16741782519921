import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Input from '../../../../../../../components/inputs/input';
import moment from 'moment';
import 'moment/locale/fr'
import DateInput from '../../../../../../../components/inputs/inputDate';
import { cn } from '../../../../../../../utils/cn';
import { BiLoaderAlt } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts } from '../../../../../../../utils/hooks/useAlert';
import { createUser, editUser } from '../../../../../../../utils/atna';



// Constantes pour les classes réutilisées
const h1ClassName = `text-[11px] text-[rgba(55,53,47,0.5)] font-medium leading-[18px] tracking-wide`;
const fieldClassName = `flex flex-col min-w-[200px] w-full max-w-[250px]`;

const NewUser = ({ fetchAllUsers, fetchData }) => {

    const [isCreating, setIsCreating] = useState(false);
    const navigate = useNavigate()
    const { addAlert } = useAlerts()
    const [userData, setUserData] = useState({
        dateEmbauche: moment(Date.now()).locale('fr').format('YYYY-MM-DD'),
        dateSortie: null
    })




    const handleCreateData = useCallback(async () => {
        setIsCreating(true);
        const resEditData = await createUser({ data: { ...userData } });
        console.log(resEditData)
        setIsCreating(false);
        fetchAllUsers()
        if (resEditData.status === 200) {
            const newId = userData.id
            fetchData(newId)
            navigate('/admin/utilisateurs/' + newId)
        }
        else return addAlert(resEditData.data);


    }, [userData]);






    return (
        <div className="flex flex-col w-full">



            <div className="flex gap-x-[10px] w-full flex-wrap">
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Id</h1>
                    <Input object={userData} setObject={setUserData} value={"id"} dataTitle={null} placeholder={""}
                        className={'w-full'}
                        type="text"
                        maxLength={5}
                        required={true}
                        validation={(str) => str.length > 5 ? { valid: false, data: `L'identifiant ne peut dépasser 5 caractères` } : { valid: true, data: null }} />
                </div>
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Nom</h1>
                    <Input object={userData} setObject={setUserData} value={"nom"} dataTitle={null} placeholder={""}
                        className={'w-full'}
                        type="text"
                        required={true}
                        validation={(str) => str.length > 255 ? { valid: false, data: `Le prénom ne peut dépasser 255 caractères` } : { valid: true, data: null }} />
                </div>
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Prénom</h1>
                    <Input object={userData} setObject={setUserData} value={"prenom"} dataTitle={null} placeholder={""}
                        className={'w-full'}
                        type="text"
                        required={true}
                        validation={(str) => str.length > 255 ? { valid: false, data: `Le nom ne peut dépasser 255 caractères` } : { valid: true, data: null }} />
                </div>
            </div>


            <div className="flex gap-x-[10px] w-full flex-wrap">
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Adresse</h1>
                    <Input object={userData} setObject={setUserData} value={"adresse"} dataTitle={null} placeholder={""}
                        className={'w-full'}
                        type="text"
                        required={false}
                        validation={(str) => str.length > 255 ? { valid: false, data: `L'adresse ne peut dépasser 255 caractères` } : { valid: true, data: null }} />
                </div>
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Ville</h1>
                    <Input object={userData} setObject={setUserData} value={"ville"} dataTitle={null} placeholder={""}
                        className={'w-full'}
                        type="text"
                        required={false}
                        validation={(str) => str.length > 255 ? { valid: false, data: `La ville ne peut dépasser 255 caractères` } : { valid: true, data: null }} />
                </div>
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Adresse suite</h1>
                    <Input object={userData} setObject={setUserData} value={"adresseSuite"} dataTitle={null} placeholder={""}
                        className={'w-full'}
                        type="text"
                        required={false}
                        validation={(str) => str.length > 255 ? { valid: false, data: `La adresse ( suite ) ne peut dépasser 255 caractères` } : { valid: true, data: null }} />
                </div>
            </div>


            <div className="flex gap-x-[10px] w-full flex-wrap">
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Numéro de sécurité</h1>
                    <Input object={userData} setObject={setUserData} value={"numSecu"} dataTitle={null} placeholder={""}
                        className={'w-full'}
                        type="int"
                        required={false}
                        validation={(str) => str.length > 255 ? { valid: false, data: `Le numéro de sécurité ne peut dépasser 255 caractères` } : { valid: true, data: null }} />
                </div>
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Date d'embauche</h1>
                    <DateInput object={userData} setObject={setUserData} inputBaseRef={null} readOnly={false} objValue={"dateEmbauche"}
                        className={"w-full"}
                        type='datetime'
                        required={false}
                        inpClassName={cn("min-h-[60px]")} />
                </div>
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Date de sortie</h1>
                    <DateInput object={userData} setObject={setUserData} inputBaseRef={null} readOnly={false} objValue={"dateSortie"}
                        className={"w-full"}
                        type='datetime'
                        required={false}
                        inpClassName={cn("min-h-[60px]")} />
                </div>
            </div>

            <div className="flex gap-x-[10px] w-full flex-wrap">
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Mail perso</h1>
                    <Input object={userData} setObject={setUserData} value={"mailPerso"} dataTitle={null} placeholder={""}
                        className={'w-full'}
                        type="text"
                        required={false}
                        validation={(str) => {
                            if (str && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str)) return { valid: false, data: `L'adresse mail est invalide` }
                            return { valid: true, data: null }
                        }} />
                </div>
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Mail pro</h1>
                    <Input object={userData} setObject={setUserData} value={"mailPro"} dataTitle={null} placeholder={""}
                        className={'w-full'}
                        type="text"
                        required={false}
                        validation={(str) => {
                            if (str && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str)) return { valid: false, data: `L'adresse mail est invalide` }
                            return { valid: true, data: null }
                        }} />
                </div>
                <div className={fieldClassName}>
                    <h1 className={h1ClassName}>Fonction</h1>
                    <Input object={userData} setObject={setUserData} value={"fonction"} dataTitle={null} placeholder={""}
                        className={'w-full'}
                        type="text"
                        required={false}
                        validation={(str) => str.length > 255 ? { valid: false, data: `La fonction ne peut dépasser 255 caractères` } : { valid: true, data: null }} />
                </div>
            </div>



            <div className={cn("flex mt-[20px] gap-x-[20px]")}>

                <div className={cn("h-[28px] rounded-[10px] atna-border !bg-black text-wh font-medium text-[10.5px] px-[20px] flex items-center cursor-pointer", isCreating && "opacity-75 pointer-events-none")} onClick={() => {
                    handleCreateData()
                }}>
                    {isCreating ? <BiLoaderAlt className='text-[13px] animate-spin' /> : 'Créer'}
                </div>



            </div>


        </div>
    );
}

export default NewUser;
